<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="1000px"
    origin="left top"
    scrollable
  >
    <v-card>
      <v-card-title>Cadastrar Funcionário</v-card-title>
      <v-card-text class="mb-6">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row v-if="!isInsert">
            <v-col class="d-flex justify-center">
              <AvatarForm
                :uploadUrl="uploadUrl"
                :associate="form"
                :media="form.media[0]"
             />
            </v-col>
          </v-row>
          <h3 class="mt-4 mb-2">Informações</h3>
          <v-row>
            <v-col cols="6"> </v-col>
            <v-col offset="4" cols="2">
              <app-code-field
                label="Código"
                v-model="form.code"
                :clear-on-auto-code="isInsert"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col md="6">
              <app-text-field v-model="form.name" label="Nome" />
            </v-col>
            <v-col md="6">
              <app-text-field v-model="form.short_name" label="Apelido" />
            </v-col>
          </v-row>
          <v-row>
            <v-col md="6">
              <app-cpf-field v-model="form.cpf" label="CPF" />
            </v-col>
            <v-col md="6">
              <app-rg-field v-model="form.rg" label="RG" />
            </v-col>
          </v-row>

          <!-- <h3 class="mt-10 mb-6">Endereço</h3>
          <v-row>
            <v-col md="4">
              <app-text-field
                v-model="form.main_address.postal_code"
                append-outer-icon="mdi-magnify"
                @click:append-outer="getZipCode()"
                label="CEP"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col md="5">
              <app-text-field
                v-model="form.main_address.address"
                label="Endereço"
              />
            </v-col>
            <v-col md="2">
              <app-text-field
                v-model="form.main_address.number"
                label="Número"
              />
            </v-col>
            <v-col md="5">
              <app-text-field
                v-model="form.main_address.complement"
                label="Complemento"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col md="5">
              <app-text-field
                v-model="form.main_address.district"
                label="Bairro"
              />
            </v-col>
            <v-col md="5">
              <app-text-field v-model="form.main_address.city" label="Cidade" />
            </v-col>
            <v-col md="2">
              <app-text-field
                v-model="form.main_address.state"
                label="Estado"
              />
            </v-col>
          </v-row> -->

          <h3 class="mt-4 mb-2">Acesso ao Sistema</h3>

          <v-row class="mt-2">
            <v-col md="6">
              <app-text-field v-model="form.username" label="Usuário" />
            </v-col>
            <v-col md="6">
              <app-text-field
                type="password"
                v-model="form.password"
                label="Senha"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <RoleSelect v-model="form.roles" />
            </v-col>
          </v-row>

          <h3 class="mt-4 mb-2">Informações no Aplicativo</h3>

          <v-row class="mt-2">
            <v-col>
              <app-text-field
                v-model="form.app_name"
                label="Nome no Aplicativo"
              />
            </v-col>
            <v-col>
              <app-text-field
                v-model="form.whatsapp_contact"
                label="WhatsApp"
              />
            </v-col>
            <v-col class="d-flex justify-space-around">
              <v-switch
                v-model="form.app_status"
                :true-value="1"
                :false-value="0"
                :label="appStatusText[form.app_status]"
              />
            </v-col>
          </v-row>

          <h3 class="mt-4 mb-2">Informações adicionais</h3>

          <v-row class="mt-2">
            <v-col md="6">
              <CompanySelect v-model="form.company" />
            </v-col>

            <v-col>
              <app-select
                :items="positions"
                v-model="form.position"
                label="Cargo"
              />
            </v-col>
            <v-col class="d-flex justify-space-around">
              <v-switch
                v-model="form.status"
                :true-value="1"
                :false-value="0"
                :label="statusText[form.status]"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions
        class="justify-end py-4"
        style="background-color: #f5f5f5"
      >
        <v-btn
          @click="dialog = false"
          text
          class="text-capitalize"
          color="secondary"
          >Cancelar</v-btn
        >
        <v-btn @click="handleSave()" class="text-capitalize" color="primary"
          >Salvar</v-btn
        >
        <!-- <v-btn @click="dialog = false" class="text-capitalize" color="primary"
          >Salvar e continuar</v-btn
        > -->
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import cep from "cep-promise";
import RoleSelect from "@/components/employees/ui/RoleSelect";
import CompanySelect from "../../company/ui/CompanySelect.vue";
import AvatarForm from "@/components/app/forms/AvatarForm";

export default {

  components: { RoleSelect, CompanySelect, AvatarForm },

  data() {
    return {
      uploadUrl: "employee/avatar",

      title: null,
      dialog: false,
      positions: [
        { text: "Vendedor", value: "vendedor" },
        { text: "Gerente", value: "gerente" },
        { text: "Caixa", value: "caixa" },
        { text: "Auxiliar administrativo", value: "auxiliar_administrativo" },
        { text: "Estoquista", value: "estoquista" },
        { text: "Comprador", value: "comprador" },
        { text: "Motorista", value: "motorista" },
        { text: "Entregador", value: "entregador" },
        { text: "Cobrador", value: "cobrador" },
        { text: "Outros", value: "outros" },
      ],
      statusText: {
        1: " Ativo",
        0: " Inativo",
      },
      appStatusText: {
        1: "Visivel no aplicativo",
        0: "Oculto no aplicativo",
      },
      rules: {
        required: (value) => !!value || "Required.",
      },

      valid: true,
      form: {},
      baseForm: {
        code: null,
        status: 1,
        name: null,
        short_name: null,
        cpf: null,
        rg: null,

        main_address: {
          address: null,
          number: null,
          complement: null,
          district: null,
          postal_code: null,
          city_district: null,
          city: null,
          state: null,
          country: null,
        },
        phone: null,
        cell_phone: null,
        email: null,
        roles: [],
        company: {},
        app_status: 0,
        whatsapp_contact: null,
        position: null,
      },
    };
  },

  computed: {
    isInsert() {
      return !this.form.id;
    },
  },

  created() {
    this.reset();
  },

  methods: {
    async open(id) {
      this.reset();

      if (id) {
        await this.showEmployee(id);
      }

      this.dialog = true;
    },

    reset() {
      this.form = JSON.parse(JSON.stringify(this.baseForm));
      // this.$refs.form.resetValidation();
      // this.$refs.form.reset();
    },

    async showEmployee(id) {
      await this.$http.$get("employee/employee/" + id).then((response) => {
        this.form = response.employee;
      });
    },

    handleSave() {
      this.$refs.form.validate();
      if (this.valid == true) {
        if (this.isInsert) {
          this.store();
        } else {
          this.update();
        }
      }
    },

    store() {
      this.$http.store("employee/employee", this.form).then((response) => {
        this.processSaved();
      });
    },

    update() {
      this.$http
        .update("employee/employee", this.form.id, this.form)
        .then((response) => {
          this.processSaved();
        });
    },

    processSaved() {
      this.$emit("store");
      this.dialog = false;
    },
    getZipCode() {
      cep(this.form.main_address.postal_code)
        .then((response) => {
          this.form.main_address.city = response.city;
          this.form.main_address.address = response.street;
          this.form.main_address.district = response.neighborhood;
          this.form.main_address.state = response.state;
        })
        .catch((error) => {
          alert(error);
        });
    },
  },
};
</script>

<style>
</style>
